import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material'; 
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';
import ListIcon from '@mui/icons-material/List';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import InfoIcon from '@mui/icons-material/Info';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listItems';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider, DatePicker } from '@mui/x-date-pickers';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio from '@mui/material/Radio';
import Grid from '@mui/material/Grid';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Switch from '@mui/material/Switch';
import FilterListIcon from '@mui/icons-material/FilterList';
import PrintIcon from '@mui/icons-material/Print';
import { useReactToPrint } from 'react-to-print';
import { AiOutlineRobot } from 'react-icons/ai'; // Importiere das AI Icon


const drawerWidth = 240;

const Prescription = React.forwardRef(({ patient, doctor, tenant, treatments,partner }, ref) => (
  <Paper ref={ref} elevation={3} style={{ padding: '20px', marginTop: '20px', backgroundColor: '#f5f5f5' }}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>Ordonnance</Typography>
        <Typography><strong>Nom :</strong> {patient.firstName} {patient.lastName}</Typography>
        <Typography><strong>Clinique :</strong> {tenant.name}</Typography>
        <Typography><strong>Médecin :</strong> Dr. {doctor.firstName} {doctor.lastName}</Typography>
        <Typography><strong>Adresse :</strong> {tenant.address }</Typography>
      </Grid>
      <Grid item xs={12} md={6} container justifyContent="flex-end">
        <Typography variant="h6">Date : {dayjs().format('DD/MM/YYYY')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Médicaments :</Typography>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px', width: '70%' }}>Médicament</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', width: '30%' }}>Date</th>
            </tr>
          </thead>
          <tbody>
            {treatments.map((treatment, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{treatment.treatment}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{dayjs(treatment.created_at).format('DD/MM/YYYY')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Typography variant="body2" style={{ marginTop: '20px' }}>Signature du médecin</Typography>
      </Grid>
      <Grid item xs={12} style={{ height: '80px' }} /> {/* Platzhalter für den Stempel */}
    </Grid>
  </Paper>
));


const DiagnosisPrint = React.forwardRef(({ patient, doctor, tenant, diagnoses,partner }, ref) => (
  <Paper ref={ref} elevation={3} style={{ padding: '20px', marginTop: '20px', backgroundColor: '#f5f5f5' }}>
    <Grid container spacing={2}>
      <Grid item xs={12} md={6}>
        <Typography variant="h6" gutterBottom>Diagnostiques</Typography>
        <Typography><strong>Nom :</strong> {patient.firstName} {patient.lastName}</Typography>
        <Typography><strong>Clinique :</strong> {tenant.name}</Typography>
        <Typography><strong>Médecin :</strong> Dr. {doctor.firstName} {doctor.lastName}</Typography>
        <Typography><strong>Adresse :</strong> {tenant.address }</Typography>
      </Grid>
      <Grid item xs={12} md={6} container justifyContent="flex-end">
        <Typography variant="h6">Date : {dayjs().format('DD/MM/YYYY')}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="h6" gutterBottom>Liste des diagnostiques :</Typography>
        <table style={{ width: '100%', borderCollapse: 'collapse' }}>
          <thead>
            <tr>
              <th style={{ border: '1px solid #ddd', padding: '8px', width: '70%' }}>Diagnostique</th>
              <th style={{ border: '1px solid #ddd', padding: '8px', width: '30%' }}>Date</th>
            </tr>
          </thead>
          <tbody>
            {diagnoses.map((diagnosis, index) => (
              <tr key={index}>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{diagnosis.diagnosis}</td>
                <td style={{ border: '1px solid #ddd', padding: '8px' }}>{dayjs(diagnosis.diagnosisDate).format('DD/MM/YYYY')}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Grid>
      <Grid item xs={12} container justifyContent="flex-end">
        <Typography variant="body2" style={{ marginTop: '20px' }}>Signature du médecin</Typography>
      </Grid>
      <Grid item xs={12} style={{ height: '80px' }} /> {/* Platzhalter für den Stempel */}
    </Grid>
  </Paper>
));

const InvoicePrint = React.forwardRef(({ patient, doctor, tenant, invoiceItems, partner }, ref) => {
  const totalAmount = invoiceItems.reduce((sum, item) => sum + item.amount, 0);
  

  // Default values for part_client and part_assurance if partner is undefined
  const partClient = partner ? partner.Client_Part : 0;
  const partAssurance = partner ? partner.Insurance_Part_assurance : 0;

  console.log("Partner:", partner); // Debugging: Partner-Objekt anzeigen
  console.log("partClient:", partClient); // Debugging: partClient-Wert anzeigen
  console.log("partAssurance:", partAssurance); // Debugging: partAssurance-Wert anzeigen

  return (
    <Paper ref={ref} elevation={3} style={{ padding: '20px', marginTop: '20px', backgroundColor: '#f5f5f5' }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography variant="h6" gutterBottom>Facture</Typography>
          <Typography><strong>Nom :</strong> {patient.firstName} {patient.lastName}</Typography>
          <Typography><strong>Clinique :</strong> {tenant.name}</Typography>
          <Typography><strong>Médecin :</strong> Dr. {doctor.firstName} {doctor.lastName}</Typography>
          <Typography><strong>Adresse :</strong> {tenant.address }</Typography>
        </Grid>
        <Grid item xs={12} md={6} container justifyContent="flex-end">
          <Typography variant="h6">Date : {dayjs().format('DD/MM/YYYY')}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="h6" gutterBottom>Articles de la facture :</Typography>
          <table style={{ width: '100%', borderCollapse: 'collapse' }}>
            <thead>
              <tr>
                <th style={{ border: '1px solid #ddd', padding: '8px', width: '20%' }}>Description</th>
                <th style={{ border: '1px solid #ddd', padding: '8px', width: '20%' }}>Prix</th>
              </tr>
            </thead>
            <tbody>
              {invoiceItems.map((item, index) => (
                <tr key={index}>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.description}</td>
                  <td style={{ border: '1px solid #ddd', padding: '8px' }}>{item.amount.toFixed(2)} MRU</td>
             
                </tr>
              ))}
            </tbody>
          </table>
        </Grid>
        <Grid item xs={12}>
          <Divider style={{ margin: '20px 0' }} />
        </Grid>
        <Grid item xs={12} container justifyContent="flex-end">
          <Typography variant="h6">Prix Total : {totalAmount.toFixed(2)} MRU</Typography>
        </Grid>
       
        <Grid item xs={12} container justifyContent="flex-end">
          <Typography variant="body2" style={{ marginTop: '20px' }}>Signature du médecin</Typography>
        </Grid>
        <Grid item xs={12} style={{ height: '80px' }} /> {/* Platzhalter für den Stempel */}
      </Grid>
    </Paper>
  );
});



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  })
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#34A836',
      dark: '#115293',
    },
    secondary: {
      main: '#e3f2fd',
    },
  },
});

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[4],
  padding: theme.spacing(2),
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: 'bold',
  backgroundColor: '#e3f2fd',
  borderRadius: '10px 10px 0 0',
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  transition: 'all 0.3s ease-in-out',
  '&:hover': {
    transform: 'scale(1.02)',
    boxShadow: theme.shadows[4],
    borderRadius: '10px',
    backgroundColor: '#f5f5f5',
  },
  '&:not(:last-child)': {
    marginBottom: theme.spacing(2),
  },
}));

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [doctors, setDoctors] = useState([]);
  const [patients, setPatients] = useState([]);
  const [tenants, setTenants] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [addAppointmentOpen, setAddAppointmentOpen] = useState(false);
  const [viewAppointmentOpen, setViewAppointmentOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [deleteConfirmOpen, setDeleteConfirmOpen] = useState(false);
  const [deleteSuccessOpen, setDeleteSuccessOpen] = useState(false);
  const [deleteErrorOpen, setDeleteErrorOpen] = useState(false);
  const [editAppointmentOpen, setEditAppointmentOpen] = useState(false);
  const [addSuccessOpen, setAddSuccessOpen] = useState(false);
  const [addErrorOpen, setAddErrorOpen] = useState(false);
  const [searchFilter, setSearchFilter] = useState('');
  const [selected, setSelected] = useState('');
  const [orderBy, setOrderBy] = useState('visitDate');
  const [order, setOrder] = useState('asc');
  const [action, setAction] = useState('');
  const [tabIndex, setTabIndex] = useState(0);
  const [editSuccessOpen, setEditSuccessOpen] = useState(false);
  const [editErrorOpen, setEditErrorOpen] = useState(false);
  const [showNotes, setShowNotes] = useState({});
  const [showReason, setShowReason] = useState({});
  const [activeButton, setActiveButton] = useState('all');
  const [filterDoctor, setFilterDoctor] = useState('');
const [diagnoses, setDiagnoses] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);
  const [partners, setPartners] = useState([]);
  const [previousAppointments, setPreviousAppointments] = useState([]);
  const [editErrorMessage, setEditErrorMessage] = useState('');



  
  const prescriptionRef = useRef();
const diagnosisPrintRef = useRef();
const invoicePrintRef = useRef();
const consultationInvoicePrintRef = useRef(); // Füge dies hinzu

  
  useEffect(() => {
    const fetchDoctors = async () => {
      const response = await axios.get('api/doctors');
      setDoctors(response.data);
    };
  
    const fetchPatients = async () => {
      const response = await axios.get('api/patients');
      setPatients(response.data);
    };
  
    const fetchTenants = async () => {
      const response = await axios.get('api/tenants');
      setTenants(response.data);
    };
  
    const fetchAppointments = async () => {
      const response = await axios.get('api/visits');
      setAppointments(response.data);
    };
  
    const fetchPartners = async () => {
      const response = await axios.get('api/partners');  // <-- NEU: Partnerdaten abrufen
      setPartners(response.data);
    };
  
    fetchDoctors();
    fetchPatients();
    fetchTenants();
    fetchAppointments();
    fetchPartners();  // <-- NEU: Abruf der Partnerdaten ausführen
  }, []);
  
  useEffect(() => {
    const createInvoiceForArrivedAppointment = async (appointment) => {

      /*
      try {
        // Holen Sie den Partner des Patienten
        const patient = patients.find(pat => pat.id === appointment.patientId);
        const partner = partners.find(partner => partner.id === patient?.partnerId);
  
        if (!partner) {
          console.error('Kein Partner für den Patienten gefunden');
          return;
        }
  
        // Bereite das Rechnungs-Item vor
        const items = [
          {
            description: "Consulation",
            amount: partner.Consultation_Price,
            
            part_client: partner.Client_Part,
            part_assurance: partner.Insurance_Part,
          },
        ];
        

        // Erstelle oder aktualisiere die Rechnung
        
        const response = await axios.post('http://localhost:5000/invoices', {
          patientId: appointment.patientId,
          visitId: appointment.id,
          tenantId: appointment.tenantId,
          Type:"Consulation",
          items: items,
        });
  
        console.log('Rechnung erstellt oder aktualisiert:', response.data);
      } catch (error) {
        console.error('Fehler beim Erstellen oder Aktualisieren der Rechnung:', error);
      }

      */

      console.log("CheckedIN");
    };
  
    appointments.forEach((appointment, index) => {
      const prevAppointment = previousAppointments[index];
      const tenant = tenants.find(ten => ten.id === appointment.tenantId); // Assuming you have access to tenants data
    
      if (
        prevAppointment && 
        prevAppointment.checkIn !== 'Arrivé' && 
        appointment.checkIn === 'Arrivé' && 
        tenant && 
        tenant.payPeriod < appointment.payPeriod 
  
      ) {
        createInvoiceForArrivedAppointment(appointment);
      } else if (
        prevAppointment && 
        prevAppointment.checkIn !== 'Arrivé' && 
        appointment.checkIn === 'Arrivé' && 
        tenant && 
        tenant.payPeriod >= appointment.payPeriod
      ) {
         
        console.log("CheckedIn");
        /*
        // Wenn die Bedingung erfüllt ist, setze totalAmount auf 0 und übergebe es an die Items
        const items = [
          {
            description: "Consulation",
            amount: 0,
            part_client: 0,
            part_assurance: 0,
            Type:"Consulation"
          },
        ];
    
        // Erstelle oder aktualisiere die Rechnung mit totalAmount = 0
        axios.post('http://localhost:5000/invoices', {
          patientId: appointment.patientId,
          visitId: appointment.id,
          tenantId: appointment.tenantId,
          Type:"Consulation",
          items: items,
        })
        .then(response => {
          console.log('Rechnung mit totalAmount = 0 erstellt oder aktualisiert:', response.data);
        })
        .catch(error => {
          console.error('Fehler beim Erstellen oder Aktualisieren der Rechnung mit totalAmount = 0:', error);
        });

        */
      }
    });
    
    
  
    setPreviousAppointments([...appointments]);
  }, [appointments]);
  

  useEffect(() => {
    if (action === 'view' && selected) {
      const appointment = appointments.find(app => app.id === selected);
      handleViewAppointmentOpen(appointment);
    } else if (action === 'edit' && selected) {
      const appointment = appointments.find(app => app.id === selected);
      handleEditAppointmentOpen(appointment);
    } else if (action === 'delete') {
      setDeleteConfirmOpen(true);
    }
    setAction('');
  }, [action, selected, appointments]);



  const navigate = useNavigate(); // Verwende useNavigate zum Weiterleiten

  useEffect(() => {
    // Axios-Interceptor zur automatischen Hinzufügung des Tokens zu jeder Anfrage
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token'); // Token aus dem lokalen Speicher abrufen
        if (token) {
          config.headers.Authorization = `Bearer ${token}`; // Token in den Authorization-Header setzen
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Axios-Interceptor zur Abfangung von abgelaufenen Token
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        // Erfolgreiche Antwort - kein Handlungsbedarf
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          // Überprüfen, ob der Fehler auf einen abgelaufenen Token hinweist
          if (error.response.data.error === 'Token abgelaufen') {
            // Token aus dem lokalen Speicher entfernen
            localStorage.removeItem('token');
            // Redirect zur Login-Seite mit useNavigate
            navigate('/login');
          }
        }
        return Promise.reject(error);
      }
    );

    // Cleanup function to eject the interceptors when the component unmounts
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate]); // Abhängigkeit auf navigate setzen

  
  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleAddAppointmentOpen = () => {
    setAddAppointmentOpen(true);
  };

  const handleEditAppointmentOpen = async (appointment) => {
    setSelectedAppointment({
      ...appointment,
      visitDate: dayjs(appointment.visitDate),
      created_at: dayjs(appointment.created_at),
    });
  
    // Fetch diagnoses and treatments independently
    try {
      const diagnosesResponse = await axios.get(`api/diagnoses?visitId=${appointment.id}`);
      setDiagnoses(diagnosesResponse.data || []);
  
      const treatmentsResponse = await axios.get(`api/treatments?visitId=${appointment.id}`);
      setTreatments(treatmentsResponse.data || []);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  
    // Fetch invoice items separately
    try {
      const invoicesResponse = await axios.get(`api/invoices?visitId=${appointment.id}`);
      setInvoiceItems(invoicesResponse.data.items || []);
      setTotalAmount(invoicesResponse.data.totalAmount || 0);
    } catch (error) {
      console.error('Failed to fetch invoices:', error);
    }
  
    setEditAppointmentOpen(true);
  };

  const handleAddAppointmentClose = () => {
    setAddAppointmentOpen(false);
  };

  const handleEditAppointmentClose = () => {
    setEditAppointmentOpen(false);
    setDiagnoses([]);
    setTreatments([]);
    setInvoiceItems([]);
    setTotalAmount(0);
  };

  const handleAddAppointment = async (newAppointment) => {
    try {
      const response = await axios.post('api/visits', newAppointment);
      setAppointments((prevAppointments) => [...prevAppointments, response.data]);
      setAddAppointmentOpen(false);
      setAddSuccessOpen(true);
  
      // PayPeriod in der Konsole ausgeben
      if (response.data.payPeriod !== undefined) {
        console.log(`Die payPeriod für diesen Besuch beträgt: ${response.data.payPeriod} Tage.`);
      }
    } catch (error) {
      console.error('Failed to add appointment', error);
      setAddAppointmentOpen(false);
      setAddErrorOpen(true);
    }
  };
  
  const handleEditAppointment = async (updatedAppointment) => {
    if (updatedAppointment.status === 'complété') {
      // Wenn der Status auf "complété" steht, das Bearbeiten verhindern
      setEditErrorMessage('');
      setEditErrorOpen(true);
      return;
    }
  
    // Verhindern der Änderung von visitDate, wenn checkIn auf "Arrivé" gesetzt ist
    const originalAppointment = appointments.find(app => app.id === updatedAppointment.id);
    if (originalAppointment?.checkIn === 'Arrivé' && !dayjs(originalAppointment.visitDate).isSame(updatedAppointment.visitDate)) {
      setEditErrorMessage('');
      setEditErrorOpen(true);
      return;
    }
  
    try {
      const response = await axios.put(`api/visits/${updatedAppointment.id}`, updatedAppointment);
      setAppointments((prevAppointments) =>
        prevAppointments.map((appointment) =>
          appointment.id === updatedAppointment.id ? response.data : appointment
        )
      );
      setEditAppointmentOpen(false);
      setEditSuccessOpen(true);
    } catch (error) {
      console.error('Failed to update appointment', error);
  
      // Setzen der Fehlermeldung aus dem Backend
      setEditErrorMessage(error.response?.data?.error || 'Échec de la mise à jour du rendez-vous');
  
      setEditAppointmentOpen(false);
      setEditErrorOpen(true);
    }
  };
  
    const handleViewAppointmentOpen = async (appointment) => {
    setSelectedAppointment({
      ...appointment,
      visitDate: dayjs(appointment.visitDate),
      created_at: dayjs(appointment.created_at),
    });
  
    // Fetch diagnoses and treatments independently
    try {
      const diagnosesResponse = await axios.get(`api/diagnoses?visitId=${appointment.id}`);
      setDiagnoses(diagnosesResponse.data || []);
  
      const treatmentsResponse = await axios.get(`api/treatments?visitId=${appointment.id}`);
      setTreatments(treatmentsResponse.data || []);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  
    // Fetch invoice items separately
    try {
      const invoicesResponse = await axios.get(`api/invoices?visitId=${appointment.id}`);
      setInvoiceItems(invoicesResponse.data.items || []);
      setTotalAmount(invoicesResponse.data.totalAmount || 0);
    } catch (error) {
      console.error('Failed to fetch invoices:', error);
    }
  
    setTabIndex(0);
    setViewAppointmentOpen(true);
  };

  const handleViewAppointmentClose = () => {
    setViewAppointmentOpen(false);
    setDiagnoses([]);
    setTreatments([]);
    setInvoiceItems([]);
    setTotalAmount(0);
  };

  const handleAppointmentClick = (id) => {
    setSelected(id);
  };

  const isSelected = (id) => selected === id;

  const handleDeleteAppointment = async () => {
    try {
      await axios.delete(`api/visits/${selected}`);
      setAppointments((prevAppointments) =>
        prevAppointments.filter((appointment) => appointment.id !== selected)
      );
      setSelected('');
      setDeleteConfirmOpen(false);
      setDeleteSuccessOpen(true);
    } catch (error) {
      console.error('Failed to delete appointment', error);
      setDeleteConfirmOpen(false);
      setDeleteErrorOpen(true);
    }
  };

  const handleSearchChange = (event) => {
    setSearchFilter(event.target.value);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleActionChange = (event) => {
    setAction(event.target.value);
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleStatusChange = async (id, status) => {
    const appointment = appointments.find(app => app.id === id);

    if (status === 'complété' && appointment.checkIn !== 'Arrivé') {
      alert('Le patient doit d\'abord être marqué comme Arrivé.');
      return;
    }

    try {
      const response = await axios.put(`api/visits/${id}`, { status });
      setAppointments((prevAppointments) =>
        prevAppointments.map((appointment) =>
          appointment.id === id ? response.data : appointment
        )
      );
    } catch (error) {
      console.error('Failed to update status', error);
    }
  };

  const handleCheckInChange = async (id, checkIn) => {
    try {
      const response = await axios.put(`api/visits/${id}`, { checkIn });
      setAppointments((prevAppointments) =>
        prevAppointments.map((appointment) =>
          appointment.id === id ? response.data : appointment
        )
      );
    } catch (error) {
      console.error('Failed to update check-in', error);
    }
  };

  const handleFilterChange = (filter) => {
    setActiveButton(filter);
  };

  const filteredAppointments = appointments.filter((appointment) => {
    if (activeButton === 'checkedIn') {
      return appointment.checkIn === 'Arrivé' && appointment.status !== 'complété';
    }
    if (activeButton === 'notArrived') {
      return appointment.checkIn !== 'Arrivé';
    }
    if (activeButton === 'completed') {
      return appointment.status === 'complété';
    }
    return true;
  }).filter((appointment) => {
    const doctor = doctors.find(doc => doc.id === appointment.doctorId);
    return (
      appointment.reason?.toLowerCase().includes(searchFilter.toLowerCase()) ||
      (doctor?.lastName?.toLowerCase().includes(searchFilter.toLowerCase())) ||
      (filterDoctor ? doctor?.id === filterDoctor : true)
    );
  });
  

  const sortedAppointments = filteredAppointments.sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    } else {
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
  });

  const toggleNotesVisibility = (id) => {
    setShowNotes((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const toggleReasonVisibility = (id) => {
    setShowReason((prev) => ({ ...prev, [id]: !prev[id] }));
  };

  const getBorderColor = (appointment, doctor) => {
    if (doctor) {
      return theme.palette.primary.main;
    }
    return appointment.checkIn === 'Arrivé' ? 'green' : 'red';
  };

  const handlePrintDiagnosis = useReactToPrint({
    content: () => diagnosisPrintRef.current,
  });

  const handlePrintPrescription = useReactToPrint({
    content: () => prescriptionRef.current,
  });

  const handlePrintInvoice = useReactToPrint({
    content: () => invoicePrintRef.current,
  });

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
      <Snackbar
  open={editErrorOpen}
  autoHideDuration={6000}
  onClose={() => setEditErrorOpen(false)}
>
  <Alert onClose={() => setEditErrorOpen(false)} severity="error">
    {editErrorMessage}
  </Alert>
</Snackbar>

        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Gestion des rendez-vous
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 4 }} />
            {secondaryListItems}
            <Divider sx={{ my: 4 }} />
          </List>
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3, marginTop: 5 }}>
          <Toolbar />
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Button
                variant={activeButton === 'all' ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => handleFilterChange('all')}
              >
                Tous les rendez-vous
              </Button>
              <Button
                variant={activeButton === 'checkedIn' ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => handleFilterChange('checkedIn')}
              >
                Arrivés
              </Button>
              <Button
                variant={activeButton === 'notArrived' ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => handleFilterChange('notArrived')}
              >
                Pas encore arrivé
              </Button>
              <Button
                variant={activeButton === 'completed' ? 'contained' : 'outlined'}
                color="primary"
                onClick={() => handleFilterChange('completed')}
              >
                Complétés
              </Button>
              <FormControl margin="dense">
                <InputLabel>Filtrer par médecin</InputLabel>
                <Select
                  value={filterDoctor}
                  onChange={(e) => setFilterDoctor(e.target.value)}
                  displayEmpty
                  inputProps={{ 'aria-label': 'Filter Doctor' }}
                  startAdornment={
                    <InputAdornment position="start">
                      <FilterListIcon />
                    </InputAdornment>
                  }
                >
                  <MenuItem value="">Tous les médecins</MenuItem>
                  {doctors.map((doctor) => (
                    <MenuItem key={doctor.id} value={doctor.id}>
                      {doctor.firstName} {doctor.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <IconButton onClick={handleAddAppointmentOpen}>
                <AddCircleIcon sx={{ color: theme.palette.primary.main }} />
              </IconButton>
              <Select
                value={action}
                onChange={handleActionChange}
                displayEmpty
                sx={{ ml: 2, color: theme.palette.primary.main, fontWeight: 'bold', borderBottom: `2px solid ${theme.palette.primary.main}` }}
              >
                <MenuItem value="" disabled>
                  Choisir une action
                </MenuItem>
                <MenuItem value="view" disabled={!selected}>
                  Voir
                </MenuItem>
                <MenuItem value="edit" disabled={!selected}>
                  Modifier
                </MenuItem>
                <MenuItem value="delete" disabled={!selected}>
                  Supprimer
                </MenuItem>
              </Select>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <TextField
                label="Recherche"
                variant="outlined"
                size="small"
                value={searchFilter}
                onChange={handleSearchChange}
                sx={{
                  bgcolor: 'white',
                  backgroundColor: '#e3f2fd',
                  borderRadius: '15px',
                  boxShadow: '0 3px 5px rgba(0, 0, 0, 0.2)',
                  '& .MuiOutlinedInput-root': {
                    borderRadius: '25px',
                    '& fieldset': {
                      borderWidth: '2px',
                      borderColor: '#2196f3',
                    },
                    '&:hover fieldset': {
                      borderWidth: '2px',
                      borderColor: '#1976d2',
                    },
                    '&.Mui-focused fieldset': {
                      borderWidth: '2px',
                      borderColor: '#1976d2',
                    },
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SearchIcon sx={{ color: '#1976d2' }} />
                    </InputAdornment>
                  ),
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2, flexWrap: 'wrap' }}>
            <Typography variant="body1" component="div" sx={{ textAlign: 'right', fontWeight: 'bold', marginBottom: 2, color: theme.palette.primary.dark }}>
              Rendez-vous : {appointments.length}
            </Typography>
          </Box>
          <StyledTableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <StyledTableCell padding="checkbox">
                    <RadioGroup
                      name="appointmentSelection"
                      value={selected}
                      onChange={(e) => handleAppointmentClick(e.target.value)}
                    >
                      <FormControlLabel
                        value="all"
                        control={<Radio color="primary" />}
                        label=""
                      />
                    </RadioGroup>
                  </StyledTableCell>
                  <StyledTableCell>Image</StyledTableCell>
                  <StyledTableCell>
                    <TableSortLabel
                      active={orderBy === 'visitDate'}
                      direction={orderBy === 'visitDate' ? order : 'asc'}
                      onClick={() => handleRequestSort('visitDate')}
                    >
                      Date de visite
                    </TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell>Patient</StyledTableCell>
                  <StyledTableCell>Médecin</StyledTableCell>
                  <StyledTableCell>Motif</StyledTableCell>
                  <StyledTableCell>Notes</StyledTableCell>
                  <StyledTableCell>Mandant</StyledTableCell>
                  <StyledTableCell>Enregistrement</StyledTableCell>
                  <StyledTableCell>Statut</StyledTableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {sortedAppointments.map((appointment) => {
                  const isItemSelected = isSelected(appointment.id);
                  const tenantName = tenants.find(tenant => tenant.id === appointment.tenantId)?.name || 'Inconnu';
                  const patient = patients.find(pat => pat.id === appointment.patientId);
                  const doctor = doctors.find(doc => doc.id === appointment.doctorId);
                  return (
                    <StyledTableRow
                      hover
                      onClick={() => handleAppointmentClick(appointment.id)}
                      role="radio"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={appointment.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Radio
                          color="primary"
                          checked={isItemSelected}
                          onChange={() => handleAppointmentClick(appointment.id)}
                          value={appointment.id}
                        />
                      </TableCell>
                      <TableCell>
                        <Avatar
                          src={patient?.imageUrl}
                          alt={patient?.firstName}
                          sx={{
                            width: 60,
                            height: 60,
                            border: `4px solid ${getBorderColor(appointment, false)}`,
                          }}
                        />
                        <Avatar
                          src={doctor?.imageUrl}
                          alt={doctor?.firstName}
                          sx={{
                            width: 60,
                            height: 60,
                            border: `4px solid ${getBorderColor(appointment, true)}`,
                          }}
                        />
                      </TableCell>
                      <TableCell>{dayjs(appointment.visitDate).format('DD/MM/YYYY HH:mm')}</TableCell>
                      <TableCell>
                        <a href={`/patients/${appointment.patientId}`} style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                          {patient ? `${patient.firstName} ${patient.lastName}` : 'Inconnu'}
                        </a>
                      </TableCell>
                      <TableCell>
                        <a href={`/doctors/${appointment.doctorId}`} style={{ textDecoration: 'none', color: theme.palette.primary.main }}>
                          {doctor ? `${doctor.firstName} ${doctor.lastName}` : 'Inconnu'}
                        </a>
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => toggleReasonVisibility(appointment.id)}>
                          <InfoIcon color="primary" />
                        </IconButton>
                        {showReason[appointment.id] && <Typography>{appointment.reason}</Typography>}
                      </TableCell>
                      <TableCell>
                        <IconButton onClick={() => toggleNotesVisibility(appointment.id)}>
                          <InfoIcon color="primary" />
                        </IconButton>
                        {showNotes[appointment.id] && <Typography>{appointment.notes}</Typography>}
                      </TableCell>
                      <TableCell>{tenantName}</TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={appointment.checkIn === 'Arrivé'}
                              onChange={() => handleCheckInChange(appointment.id, appointment.checkIn === 'Arrivé' ? 'Pas encore arrivé' : 'Arrivé')}
                              color="primary"
                            />
                          }
                          label={appointment.checkIn === 'Arrivé' ? 'Arrivé' : 'Pas encore arrivé'}
                        />
                      </TableCell>
                      <TableCell>
                        <FormControlLabel
                          control={
                            <Switch
                              checked={appointment.status === 'complété'}
                              onChange={() => handleStatusChange(appointment.id, appointment.status === 'complété' ? 'ouvert' : 'complété')}
                              color="primary"
                            />
                          }
                          label={appointment.status === 'complété' ? 'Complété' : 'Ouvert'}
                        />
                      </TableCell>
                    </StyledTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </StyledTableContainer>
          <AddAppointmentDialog
            open={addAppointmentOpen}
            onClose={handleAddAppointmentClose}
            onAddAppointment={handleAddAppointment}
            patients={patients}
            doctors={doctors}
            tenants={tenants}
          />
          {selectedAppointment && (
            <>
              <ViewAppointmentDialog
  open={viewAppointmentOpen}
  onClose={handleViewAppointmentClose}
  appointment={selectedAppointment}
  tabIndex={tabIndex}
  setTabIndex={setTabIndex}
  patients={patients}
  doctors={doctors}
  tenants={tenants}
  partners={partners} 
  appointments={appointments}
  diagnosisPrintRef={diagnosisPrintRef}
  prescriptionRef={prescriptionRef}
  invoicePrintRef={invoicePrintRef}
  consultationInvoicePrintRef={consultationInvoicePrintRef} // Übergib das Ref hier
  handlePrintDiagnosis={handlePrintDiagnosis}
  handlePrintPrescription={handlePrintPrescription}
  handlePrintInvoice={handlePrintInvoice}
  diagnoses={diagnoses}
  treatments={treatments}
  invoiceItems={invoiceItems}
  totalAmount={totalAmount}
/>

              <EditAppointmentDialog
                open={editAppointmentOpen}
                onClose={handleEditAppointmentClose}
                appointment={selectedAppointment}
                onEditAppointment={handleEditAppointment}
                patients={patients}
                doctors={doctors}
                tenants={tenants}
                partners={partners} // <-- Partnerdaten übergeben
                diagnoses={diagnoses}
                treatments={treatments}
                invoiceItems={invoiceItems}
                totalAmount={totalAmount}
              />
            </>
          )}
          <Dialog
            open={deleteConfirmOpen}
            onClose={() => setDeleteConfirmOpen(false)}
          >
            <DialogTitle>Confirmer la suppression</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Êtes-vous sûr de vouloir supprimer le rendez-vous sélectionné ?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setDeleteConfirmOpen(false)} color="primary">
                Annuler
              </Button>
              <Button onClick={handleDeleteAppointment} color="primary">
                Supprimer
              </Button>
            </DialogActions>
          </Dialog>
          <Snackbar
            open={deleteSuccessOpen}
            autoHideDuration={6000}
            onClose={() => setDeleteSuccessOpen(false)}
          >
            <Alert onClose={() => setDeleteSuccessOpen(false)} severity="success">
              Rendez-vous supprimé avec succès !
            </Alert>
          </Snackbar>
          <Snackbar
            open={deleteErrorOpen}
            autoHideDuration={6000}
            onClose={() => setDeleteErrorOpen(false)}
          >
            <Alert onClose={() => setDeleteErrorOpen(false)} severity="error">
              Échec de la suppression du rendez-vous !
            </Alert>
          </Snackbar>
          <Snackbar
            open={addSuccessOpen}
            autoHideDuration={6000}
            onClose={() => setAddSuccessOpen(false)}
          >
            <Alert onClose={() => setAddSuccessOpen(false)} severity="success">
              Rendez-vous ajouté avec succès !
            </Alert>
          </Snackbar>
          <Snackbar
            open={addErrorOpen}
            autoHideDuration={6000}
            onClose={() => setAddErrorOpen(false)}
          >
            <Alert onClose={() => setAddErrorOpen(false)} severity="error">
              Échec de l'ajout du rendez-vous !
            </Alert>
          </Snackbar>
          <Snackbar
            open={editSuccessOpen}
            autoHideDuration={6000}
            onClose={() => setEditSuccessOpen(false)}
          >
            <Alert onClose={() => setEditSuccessOpen(false)} severity="success">
              Rendez-vous mis à jour avec succès !
            </Alert>
          </Snackbar>
          <Snackbar
            open={editErrorOpen}
            autoHideDuration={6000}
            onClose={() => setEditErrorOpen(false)}
          >
            <Alert onClose={() => setEditErrorOpen(false)} severity="error">
              Échec de la mise à jour du rendez-vous !
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </ThemeProvider>
  );
}  


const AddAppointmentDialog = ({ open, onClose, onAddAppointment, patients, doctors, tenants }) => {
  const [patientId, setPatientId] = useState('');
  const [doctorId, setDoctorId] = useState('');
  const [visitDate, setVisitDate] = useState(dayjs());
  const [visitTime, setVisitTime] = useState('');
  const [reason, setReason] = useState('');
  const [notes, setNotes] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [status, setStatus] = useState('ouvert');
  const [duplicateBilling, setDuplicateBilling] = useState(false); // Default to Yes
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredPatients, setFilteredPatients] = useState(patients);

  useEffect(() => {
    // Filter patients based on search term
    setFilteredPatients(
      patients.filter(patient =>
        patient.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        patient.phoneNumber.includes(searchTerm)
      )
    );
  }, [searchTerm, patients]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const visitDateTime = dayjs(visitDate).set('hour', dayjs(visitTime, 'HH:mm').hour()).set('minute', dayjs(visitTime, 'HH:mm').minute());
      const newAppointment = {
        patientId,
        doctorId,
        visitDate: visitDateTime.toISOString(),
        reason,
        notes,
        tenantId,
        status,
        checkIn: 'Pas encore arrivé',
        duplicateBilling: duplicateBilling ? 'yes' : 'no', // Send 'yes' or 'no' based on the switch
      };
      onAddAppointment(newAppointment);
    } catch (error) {
      console.error('Failed to add appointment', error);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>Ajouter un rendez-vous</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Rechercher un patient</InputLabel>
                <TextField
                  margin="dense"
                  label=""
                  fullWidth
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Select
                  value={patientId}
                
                  onChange={(e) => setPatientId(e.target.value)}
                  required
                >
                  {filteredPatients.map((patient) => (
                    <MenuItem key={patient.id} value={patient.id}>
                      {patient.firstName} {patient.lastName} - {patient.phoneNumber}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Médecin</InputLabel>
                <Select
                  value={doctorId}
                  onChange={(e) => setDoctorId(e.target.value)}
                  required
                >
                  {doctors.map((doctor) => (
                    <MenuItem key={doctor.id} value={doctor.id}>
                      {doctor.firstName} {doctor.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date de visite"
                  value={visitDate}
                  onChange={(newValue) => setVisitDate(newValue)}
                  renderInput={(params) => <TextField {...params} fullWidth margin="dense" required />}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Heure de visite"
                type="time"
                fullWidth
                value={visitTime}
                onChange={(e) => setVisitTime(e.target.value)}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Motif"
                fullWidth
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Notes"
                fullWidth
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Mandant</InputLabel>
                <Select
                  value={tenantId}
                  onChange={(e) => setTenantId(e.target.value)}
                  required
                >
                  {tenants.map((tenant) => (
                    <MenuItem key={tenant.id} value={tenant.id}>
                      {tenant.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Statut</InputLabel>
                <Select
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                  required
                >
                  <MenuItem value="ouvert">Ouvert</MenuItem>
                  <MenuItem value="complété">Complété</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={duplicateBilling}
                    onChange={(e) => setDuplicateBilling(e.target.checked)}
                    color="primary"
                  />
                }
                label="Doppelrechnung"
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={onClose}>Annuler</Button>
            <Button type="submit" color="primary">
              Ajouter
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

const EditAppointmentDialog = ({ open, onClose, appointment, onEditAppointment, patients, doctors, tenants, partners }) => {
  const [patientId, setPatientId] = useState('');
  const [doctorId, setDoctorId] = useState('');
  const [visitDate, setVisitDate] = useState(dayjs());
  const [visitTime, setVisitTime] = useState('');
  const [reason, setReason] = useState('');
  const [notes, setNotes] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [status, setStatus] = useState('');
  const [diagnoses, setDiagnoses] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [invoiceDescription, setInvoiceDescription] = useState('');
  const [invoiceAmount, setInvoiceAmount] = useState('');
  const [duplicateBilling, setDuplicateBilling] = useState(true); // Default to Yes
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const [openAiDialog, setOpenAiDialog] = useState(false); // Zustand für AI-Dialog
  const [symptoms, setSymptoms] = useState(''); // Zustand für Symptomeingabe
  const [aiResponse, setAiResponse] = useState(''); // Zustand für die Antwort von ChatGPT
  const [loading, setLoading] = useState(false); // État pour l'indicateur de chargement


  useEffect(() => {
    if (open && appointment) {
      setPatientId(appointment.patientId || '');
      setDoctorId(appointment.doctorId || '');
      setVisitDate(dayjs(appointment.visitDate) || dayjs());
      setVisitTime(dayjs(appointment.visitDate).format('HH:mm') || '');
      setReason(appointment.reason || '');
      setNotes(appointment.notes || '');
      setTenantId(appointment.tenantId || '');
      setStatus(appointment.status || 'ouvert');
      setDuplicateBilling(appointment.duplicateBilling === 'yes'); // Set based on appointment data

      const fetchData = async () => {
        try {
          const [diagnosesResponse, treatmentsResponse, invoicesResponse] = await Promise.all([
            axios.get(`api/diagnoses?visitId=${appointment.id}`),
            axios.get(`api/treatments?visitId=${appointment.id}`),
            axios.get(`api/invoices?visitId=${appointment.id}`)
          ]);

          setDiagnoses(diagnosesResponse.data || []);
          setTreatments(treatmentsResponse.data || []);
          if (invoicesResponse.data) {
            setInvoiceItems(invoicesResponse.data.items || []);
          }
        } catch (error) {
          console.error('Failed to fetch data:', error);
        }
      };

      fetchData();
    }
  }, [open, appointment]);

  const handleDiagnosisChange = (index, field, value) => {
    const newDiagnoses = [...diagnoses];
    newDiagnoses[index][field] = value;
    setDiagnoses(newDiagnoses);
  };

  const removeDiagnosis = async (index, id) => {
    if (id) {
      try {
        await axios.delete(`api/diagnoses/${id}`);
        const newDiagnoses = diagnoses.filter((_, i) => i !== index);
        setDiagnoses(newDiagnoses);
        setSnackbarMessage('Diagnosis supprimée avec succès.');
        setSnackbarSeverity('success');
      } catch (error) {
        console.error('Failed to delete diagnosis', error);
        setSnackbarMessage('Échec de la suppression du diagnostic.');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
      }
    } else {
      const newDiagnoses = diagnoses.filter((_, i) => i !== index);
      setDiagnoses(newDiagnoses);
    }
  };

  const addDiagnosis = () => {
    setDiagnoses([...diagnoses, { diagnosis: '', diagnosisDate: dayjs().format('YYYY-MM-DD'), patientId, tenantId }]);
  };

  const handleTreatmentChange = (index, value) => {
    const newTreatments = [...treatments];
    newTreatments[index].treatment = value;
    setTreatments(newTreatments);
  };

  const removeTreatment = async (index, id) => {
    if (id) {
      try {
        await axios.delete(`api/treatments/${id}`);
        const newTreatments = treatments.filter((_, i) => i !== index);
        setTreatments(newTreatments);
        setSnackbarMessage('Traitement supprimé avec succès.');
        setSnackbarSeverity('success');
      } catch (error) {
        console.error('Failed to delete treatment', error);
        setSnackbarMessage('Échec de la suppression du traitement.');
        setSnackbarSeverity('error');
      } finally {
        setSnackbarOpen(true);
      }
    } else {
      const newTreatments = treatments.filter((_, i) => i !== index);
      setTreatments(newTreatments);
    }
  };
  
  const addTreatment = () => {
    setTreatments([...treatments, { treatment: '', treatmentDate: dayjs().format('YYYY-MM-DD'), patientId, tenantId }]);
  };

  const patient = patients?.find(pat => pat.id === patientId);
  const partner = partners?.find(partner => partner.id === patient?.partnerId);

  const handleAddInvoiceItem = () => {
    if (invoiceDescription && invoiceAmount) {
      const partClient = partner ? (partner.Client_Part) : 0;
      const partAssurance = partner ? (partner.Insurance_Part) : 0;

      console.log("Beschreibung:", invoiceDescription);
      console.log("Betrag:", parseFloat(invoiceAmount));
      console.log("Part Client:", partClient);
      console.log("Part Assurance:", partAssurance);
      console.log("Partner:", partner);

      setInvoiceItems([...invoiceItems, {
        description: invoiceDescription,
        amount: parseFloat(invoiceAmount),
        part_client: partClient,
        part_assurance: partAssurance
      }]);

      setInvoiceDescription('');
      setInvoiceAmount('');
    }
  };

  const handleRemoveInvoiceItem = async (index, id) => {
    try {
      const response = await axios.delete(`api/invoiceItems/${id}`);
      
      setSnackbarMessage(response.data.message || 'Article supprimé avec succès');
      setSnackbarSeverity('success');
      
      const newItems = invoiceItems.filter((_, i) => i !== index);
      setInvoiceItems(newItems);
      
    } catch (error) {
      setSnackbarMessage(error.response?.data?.error || 'Échec de la suppression de l\'article');
      setSnackbarSeverity('error');
    } finally {
      setSnackbarOpen(true);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const visitDateTime = dayjs(visitDate)
        .set('hour', dayjs(visitTime, 'HH:mm').hour())
        .set('minute', dayjs(visitTime, 'HH:mm').minute());
      const updatedAppointment = {
        ...appointment,
        patientId,
        doctorId,
        visitDate: visitDateTime.toISOString(),
        reason,
        notes,
        tenantId,
        status,
        duplicateBilling: duplicateBilling ? 'yes' : 'no', // Send 'yes' or 'no' based on the switch
      };
      await onEditAppointment(updatedAppointment);

      await Promise.all([
        ...diagnoses.map(async (diagnosis) => {
          if (diagnosis.id) {
            await axios.put(`api/diagnoses/${diagnosis.id}`, diagnosis);
          } else {
            await axios.post('api/diagnoses', { ...diagnosis, visitId: appointment.id });
          }
        }),
        ...treatments.map(async (treatment) => {
          if (treatment.id) {
            await axios.put(`api/treatments/${treatment.id}`, treatment);
          } else {
            await axios.post('api/treatments', { ...treatment, visitId: appointment.id, patientId });
          }
        }),
      ]);

      if (invoiceItems.length > 0) {
        await axios.post('api/invoices', {
          patientId,
          visitId: appointment.id,
          tenantId,
          items: invoiceItems,
          Type: "Facture",
        });
      }

      onClose();
    } catch (error) {
      console.error('Failed to edit appointment', error);
    }
  };

  const handleDialogClose = () => {
    setDiagnoses([]);
    setTreatments([]);
    setInvoiceItems([]);
    onClose();
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };

  // Methode zum Öffnen des AI-Dialogs
  const handleOpenAiDialog = () => {
    setOpenAiDialog(true);
  };

  // Methode zum Schließen des AI-Dialogs
  const handleCloseAiDialog = () => {
    setOpenAiDialog(false);
    setSymptoms('');
    setAiResponse('');
    setLoading(false);
  };

  // Methode zum Senden der Symptome an ChatGPT und Abrufen der Antwort
    // Méthode pour envoyer les symptômes à ChatGPT et obtenir la réponse
    const handleSendToAi = async () => {
      setLoading(true); // Afficher l'indicateur de chargement
      try {
        const response = await axios.post('api/Analyze', {
          symptoms: `${symptoms} (Veuillez répondre en français. je veux une response formater en 2 partis. une partie qui explique une cause possible des symptome et 2. partie quie propose un traitement. je veux une courte reponse. pour que le docteur ne lise pas longtemps)`
        });
        setAiResponse(response.data.analysis); // Réponse en français
      } catch (error) {
        console.error('Échec de l\'analyse des symptômes:', error);
      } finally {
        setLoading(false); // Masquer l'indicateur de chargement après la réponse
      }
    };
  

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="md">
      <DialogTitle>Modifier le rendez-vous</DialogTitle>
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Patient</InputLabel>
                <Select value={patientId} onChange={(e) => setPatientId(e.target.value)} required>
                  {patients.map((patient) => (
                    <MenuItem key={patient.id} value={patient.id}>
                      {patient.firstName} {patient.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Médecin</InputLabel>
                <Select value={doctorId} onChange={(e) => setDoctorId(e.target.value)} required>
                  {doctors.map((doctor) => (
                    <MenuItem key={doctor.id} value={doctor.id}>
                      {doctor.firstName} {doctor.lastName}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Date de visite"
                  value={visitDate}
                  onChange={(newValue) => setVisitDate(newValue)}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      fullWidth
                      margin="dense"
                      required
                      disabled={selectedAppointment?.checkIn === 'Arrivé'} // Disable if checkIn is 'Arrivé'
                    />
                  )}
                />
              </LocalizationProvider>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Heure de visite"
                type="time"
                fullWidth
                value={visitTime}
                onChange={(e) => setVisitTime(e.target.value)}
                required
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Motif"
                fullWidth
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Notes"
                fullWidth
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Mandant</InputLabel>
                <Select value={tenantId} onChange={(e) => setTenantId(e.target.value)} required>
                  {tenants.map((tenant) => (
                    <MenuItem key={tenant.id} value={tenant.id}>
                      {tenant.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth margin="dense">
                <InputLabel>Statut</InputLabel>
                <Select value={status} onChange={(e) => setStatus(e.target.value)} required>
                  <MenuItem value="ouvert">Ouvert</MenuItem>
                  <MenuItem value="complété">Complété</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} display="flex" alignItems="center">
              <Typography variant="h6" style={{ flexGrow: 1 }}>
                Diagnostiques
              </Typography>
              <IconButton onClick={handleOpenAiDialog}>
              <AiOutlineRobot style={{ fontSize: '32px', color: 'green' }} /> {/* Icône agrandie et verte */}
              </IconButton>
            </Grid>
            {diagnoses.map((diagnosis, index) => (
              <Grid container spacing={2} key={index}>
                <Grid item xs={6}>
                  <TextField
                    margin="dense"
                    label={`Diagnostique ${index + 1}`}
                    fullWidth
                    value={diagnosis.diagnosis}
                    onChange={(e) => handleDiagnosisChange(index, 'diagnosis', e.target.value)}
                    multiline
                    rows={2}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    label="Date du diagnostique"
                    type="date"
                    fullWidth
                    value={dayjs(diagnosis.diagnosisDate).format('YYYY-MM-DD')}
                    onChange={(e) => handleDiagnosisChange(index, 'diagnosisDate', e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <Button onClick={() => removeDiagnosis(index, diagnosis.id)} color="secondary">
                    Supprimer le diagnostique
                  </Button>
                </Grid>
              </Grid>
            ))}
            <Button onClick={addDiagnosis} color="primary">
              Ajouter un diagnostique
            </Button>
            <Grid item xs={12}>
              <Typography variant="h6">Traitements</Typography>
              {treatments.map((treatment, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={12}>
                    <TextField
                      margin="dense"
                      label={`Traitement ${index + 1}`}
                      fullWidth
                      value={treatment.treatment}
                      onChange={(e) => handleTreatmentChange(index, e.target.value)}
                      multiline
                      rows={2}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Button onClick={() => removeTreatment(index, treatment.id)} color="secondary">
                      Supprimer le traitement
                    </Button>
                  </Grid>
                </Grid>
              ))}
              <Button onClick={addTreatment} color="primary">
                Ajouter un traitement
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Articles de la facture</Typography>
              {invoiceItems.map((item, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={8}>
                    <TextField
                      margin="dense"
                      label={`Description ${index + 1}`}
                      fullWidth
                      value={item.description}
                      onChange={(e) => {
                        const newInvoiceItems = [...invoiceItems];
                        newInvoiceItems[index].description = e.target.value;
                        setInvoiceItems(newInvoiceItems);
                      }}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      margin="dense"
                      label="Montant"
                      fullWidth
                      value={item.amount}
                      onChange={(e) => {
                        const newInvoiceItems = [...invoiceItems];
                        newInvoiceItems[index].amount = e.target.value;
                        setInvoiceItems(newInvoiceItems);
                      }}
                    />
                  </Grid>
                  <Grid item xs={1}>
                    <Button onClick={() => handleRemoveInvoiceItem(index, item.id)} color="secondary">
                      Supprimer
                    </Button>
                  </Grid>
                </Grid>
              ))}
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <TextField
                    margin="dense"
                    label="Nouvelle description"
                    fullWidth
                    value={invoiceDescription}
                    onChange={(e) => setInvoiceDescription(e.target.value)}
                  />
                </Grid>
                <Grid item xs={3}>
                  <TextField
                    margin="dense"
                    label="Nouveau montant"
                    fullWidth
                    value={invoiceAmount}
                    onChange={(e) => setInvoiceAmount(e.target.value)}
                  />
                </Grid>
                <Grid item xs={1}>
                  <Button onClick={handleAddInvoiceItem} color="primary">
                    Ajouter
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                control={
                  <Switch
                    checked={duplicateBilling}
                    onChange={(e) => setDuplicateBilling(e.target.checked)}
                    color="primary"
                  />
                }
                label="Doppelrechnung"
              />
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={handleDialogClose}>Annuler</Button>
            <Button type="submit" color="primary">
              Enregistrer
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
      {/* Snackbar für Benachrichtigungen */}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
      >
        <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>

      {/* AI Dialog */}
      <Dialog open={openAiDialog} onClose={handleCloseAiDialog}>
        <DialogTitle>Analyse des symptômes</DialogTitle>
        <DialogContent>
          <TextField
            label="Symptômes"
            fullWidth
            multiline
            rows={4}
            value={symptoms}
            onChange={(e) => setSymptoms(e.target.value)}
          />
          {aiResponse && (
            <Typography variant="body1" style={{ marginTop: '20px' }}>
              Réponse de l'IA: {aiResponse}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseAiDialog}>Annuler</Button>
          <Button onClick={handleSendToAi} color="primary">
            Envoyer à l'IA
          </Button>
        </DialogActions>
      </Dialog>
    </Dialog>
  );
};


const ViewAppointmentDialog = ({
  open,
  onClose,
  appointment,
  tabIndex,
  setTabIndex,
  patients,
  doctors,
  tenants,
  partners,
}) => {
  const [diagnoses, setDiagnoses] = useState([]);
  const [treatments, setTreatments] = useState([]);
  const [invoiceItems, setInvoiceItems] = useState([]);
  const [consultationItems, setConsultationItems] = useState([]);
  const [invoiceConsultation, setInvoiceConsultation] = useState(null);

  const prescriptionRef = useRef();
  const diagnosisPrintRef = useRef();
  const invoicePrintRef = useRef();
  const consultationInvoicePrintRef = useRef();

  useEffect(() => {
    if (open) {
      clearFormFields();

      if (appointment) {
        fetchData();
      }
    }
  }, [open, appointment]);

  const clearFormFields = () => {
    setDiagnoses([]);
    setTreatments([]);
    setInvoiceItems([]);
    setConsultationItems([]);
    setInvoiceConsultation(null);
  };
  const fetchData = async () => {
    try {
      const diagnosesResponse = await axios.get(`api/diagnoses?visitId=${appointment.id}`);
      console.log("Diagnoses Response:", diagnosesResponse.data);
      setDiagnoses(diagnosesResponse.data || []);
    } catch (error) {
      console.error('Failed to fetch diagnoses:', error);
    }
  
    try {
      const treatmentsResponse = await axios.get(`api/treatments?visitId=${appointment.id}`);
      console.log("Treatments Response:", treatmentsResponse.data);
      setTreatments(treatmentsResponse.data.length > 0 ? treatmentsResponse.data : []);
    } catch (error) {
      console.error('Failed to fetch treatments:', error);
    }
  
    try {
      const invoicesResponse = await axios.get(`api/invoices?visitId=${appointment.id}`);
      console.log("Invoices Response:", invoicesResponse.data);
      setInvoiceItems(invoicesResponse.data.items || []);
    } catch (error) {
      console.error('Failed to fetch invoices:', error);
    }
  
    try {
      const consultationInvoiceResponse = await axios.get(`api/invoiceconsultation?visitId=${appointment.id}`);
      console.log("Consultation Invoice Response:", consultationInvoiceResponse.data);
  
      if (consultationInvoiceResponse.data && consultationInvoiceResponse.data.invoice && consultationInvoiceResponse.data.items) {
        setInvoiceConsultation(consultationInvoiceResponse.data.invoice);
        setConsultationItems(consultationInvoiceResponse.data.items);
        console.log("Consultation Invoice:", consultationInvoiceResponse.data.invoice);
        console.log("Consultation Items:", consultationInvoiceResponse.data.items);
      } else {
        console.log("No consultation invoice or items found");
      }
    } catch (error) {
      console.error('Failed to fetch consultation invoice:', error);
    }
  };
  
  

  const handleDialogClose = () => {
    clearFormFields();
    onClose();
  };

  const handlePrintDiagnosis = useReactToPrint({
    content: () => diagnosisPrintRef.current,
  });

  const handlePrintPrescription = useReactToPrint({
    content: () => prescriptionRef.current,
  });

  const handlePrintInvoice = useReactToPrint({
    content: () => invoicePrintRef.current,
  });

  const handlePrintConsultationInvoice = useReactToPrint({
    content: () => consultationInvoicePrintRef.current,
  });

  const doctor = doctors.find((doc) => doc.id === appointment.doctorId);
  const tenant = tenants.find((ten) => ten.id === appointment.tenantId);
  const patient = patients.find((pat) => pat.id === appointment.patientId);

  const partner = partners.find((partner) => partner.id === patient.partnerId);

  return (
    <Dialog open={open} onClose={handleDialogClose} fullWidth maxWidth="md">
      <DialogTitle>Voir le rendez-vous</DialogTitle>
      <DialogContent>
        <Tabs value={tabIndex} onChange={(e, newValue) => setTabIndex(newValue)}>
          <Tab label="Général" />
          <Tab label="Détails" />
        </Tabs>
        {tabIndex === 0 && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                autoFocus
                margin="dense"
                label="Date de visite"
                fullWidth
                value={dayjs(appointment.visitDate).format('DD/MM/YYYY')}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Patient"
                fullWidth
                value={patient ? `${patient.firstName} ${patient.lastName}` : 'Inconnu'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Médecin"
                fullWidth
                value={doctor ? `${doctor.firstName} ${doctor.lastName}` : 'Inconnu'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Motif"
                fullWidth
                value={appointment.reason}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              {partner ? (
                <TextField
                  margin="dense"
                  label="Assurance"
                  fullWidth
                  value={partner.Name}
                  InputProps={{
                    readOnly: true,
                  }}
                />
              ) : (
                <Typography variant="body2" color="textSecondary">
                  Assurance: Inconnu
                </Typography>
              )}
            </Grid>
      
            <Grid item xs={12} md={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={appointment.duplicateBilling === 'no'}
                    color="primary"
                    disabled
                  />
                }
                label="Doppelrechnung"
              />
            </Grid>
          </Grid>
        )}
        {tabIndex === 1 && (
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Notes"
                fullWidth
                value={appointment.notes}
                InputProps={{
                  readOnly: true,
                }}
                multiline
                rows={4}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Mandant"
                fullWidth
                value={tenant ? tenant.name : 'Inconnu'}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                margin="dense"
                label="Statut"
                fullWidth
                value={appointment.status}
                InputProps={{
                  readOnly: true,
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Diagnostiques</Typography>
              {diagnoses.map((diagnosis, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      label={`Diagnostique ${index + 1}`}
                      fullWidth
                      value={diagnosis.diagnosis}
                      InputProps={{
                        readOnly: true,
                      }}
                      multiline
                      rows={4}
                    />
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                      margin="dense"
                      label="Date du diagnostique"
                      type="date"
                      fullWidth
                      value={dayjs(diagnosis.diagnosisDate).format('YYYY-MM-DD')}
                      InputProps={{
                        readOnly: true,
                      }}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">Traitements</Typography>
              {treatments.map((treatment, index) => (
                <Grid container spacing={2} key={index}>
                  <Grid item xs={6}>
                    <TextField
                      margin="dense"
                      label={`Traitement ${index + 1}`}
                      fullWidth
                      value={treatment.treatment}
                      InputProps={{
                        readOnly: true,
                      }}
                      multiline
                      rows={4}
                    />
                  </Grid>
                </Grid>
              ))}
            </Grid>
           
            <Grid item xs={12}>
              <Typography variant="h6">Facture</Typography>
              <InvoicePrint
                ref={invoicePrintRef}
                patient={patient}
                doctor={doctor}
                tenant={tenant}
                invoiceItems={invoiceItems}
                partner={partner}
            
              />
              <Prescription
                ref={prescriptionRef}
                patient={patient}
                doctor={doctor}
                tenant={tenant}
                treatments={treatments}
                partner={partner}
              />
              <DiagnosisPrint
                ref={diagnosisPrintRef}
                patient={patient}
                doctor={doctor}
                tenant={tenant}
                diagnoses={diagnoses}
                partner={partner}
                
               
              />
            </Grid>
          </Grid>
        )}
        <DialogActions>
          <Button onClick={handleDialogClose}>Fermer</Button>
          <Button onClick={handlePrintDiagnosis} color="primary" startIcon={<PrintIcon />}>
            Imprimer les diagnostiques
          </Button>
          <Button onClick={handlePrintPrescription} color="primary" startIcon={<PrintIcon />}>
            Imprimer l'ordonnance
          </Button>
          <Button onClick={handlePrintInvoice} color="primary" startIcon={<PrintIcon />}>
            Imprimer la facture
          </Button>
          {invoiceConsultation && consultationItems.length > 0 && (
            <Button onClick={handlePrintConsultationInvoice} color="primary" startIcon={<PrintIcon />}>
              Imprimer la facture de consultation
            </Button>
          )}
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};
