import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Button,
  IconButton,
  Snackbar,
  Alert,
  styled,
  CssBaseline,
  AppBar as MuiAppBar,
  Toolbar,
  Badge,
  Divider,
  List,
  Drawer as MuiDrawer,
  Tabs,
  Tab,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import { mainListItems, secondaryListItems } from './listItems';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: '#34A836', // Hier wird der Hintergrundfarbe gesetzt
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));


const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const StyledCard = styled(Card)(({ theme }) => ({
  maxWidth: 345,
  margin: 'auto',
  transition: '0.3s',
  boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
  '&:hover': {
    boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
  },
}));

function PartnersConfig() {
  const [partners, setPartners] = useState([]);
  const [tenants, setTenants] = useState([]); 
  const [addPartnerOpen, setAddPartnerOpen] = useState(false);
  const [editPartnerOpen, setEditPartnerOpen] = useState(false);
  const [newPartner, setNewPartner] = useState({
    Name: '',
    PhoneNumber: '',
    Consultation_Price: '',
    Client_Part: 0,
    Insurance_Part: 100,
    Description: '',
    imageUrl: '',
    tenantId: '',
    
  });
  const [partnerToEdit, setPartnerToEdit] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  const [errors, setErrors] = useState({});

  const fetchPartners = async () => {
    try {
      const response = await axios.get('api/partners');
      setPartners(response.data);
    } catch (error) {
      console.error('Erreur lors du chargement des partenaires', error);
    }
  };

  const navigate = useNavigate(); // Verwende useNavigate zum Weiterleiten

  useEffect(() => {
    // Axios-Interceptor zur automatischen Hinzufügung des Tokens zu jeder Anfrage
    const requestInterceptor = axios.interceptors.request.use(
      (config) => {
        const token = localStorage.getItem('token'); // Token aus dem lokalen Speicher abrufen
        if (token) {
          config.headers.Authorization = `Bearer ${token}`; // Token in den Authorization-Header setzen
        }
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    // Axios-Interceptor zur Abfangung von abgelaufenen Token
    const responseInterceptor = axios.interceptors.response.use(
      (response) => {
        // Erfolgreiche Antwort - kein Handlungsbedarf
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          // Überprüfen, ob der Fehler auf einen abgelaufenen Token hinweist
          if (error.response.data.error === 'Token abgelaufen') {
            // Token aus dem lokalen Speicher entfernen
            localStorage.removeItem('token');
            // Redirect zur Login-Seite mit useNavigate
            navigate('/login');
          }
        }
        return Promise.reject(error);
      }
    );

    // Cleanup function to eject the interceptors when the component unmounts
    return () => {
      axios.interceptors.request.eject(requestInterceptor);
      axios.interceptors.response.eject(responseInterceptor);
    };
  }, [navigate]); // Abhängigkeit auf navigate setzen

  // Laden der Tenants für das Dropdown-Menü
  useEffect(() => {
    const fetchTenants = async () => {
      try {
        const response = await axios.get('api/tenants');
        setTenants(response.data);
      } catch (error) {
        console.error('Erreur lors du chargement des locataires', error);
      }
    };

    fetchTenants();
    fetchPartners(); // Rufen Sie die Funktion hier auf, um die Partnerdaten beim Laden der Komponente abzurufen
  }, []);

  const validateForm = () => {
    let tempErrors = {};
    if (!newPartner.Name) tempErrors.Name = "Le nom est requis";
    if (!newPartner.PhoneNumber) tempErrors.PhoneNumber = "Le téléphone est requis";
    if (!newPartner.Consultation_Price) tempErrors.Consultation_Price = "Le prix de la consultation est requis";
    if (newPartner.Client_Part === '' || newPartner.Client_Part < 0 || newPartner.Client_Part > 100) {
      tempErrors.Client_Part = "La part du client doit être comprise entre 0 et 100";
    }
    if (newPartner.Insurance_Part === '' || newPartner.Insurance_Part < 0 || newPartner.Insurance_Part > 100) {
      tempErrors.Insurance_Part = "La part de l'assurance doit être comprise entre 0 et 100";
    }
    if (!newPartner.tenantId) tempErrors.tenantId = "Le mandataire est requis";
    setErrors(tempErrors);
    return Object.keys(tempErrors).length === 0;
  };

  const handleClientPartChange = (e) => {
    const clientPart = parseInt(e.target.value);
    if (!isNaN(clientPart) && clientPart >= 0 && clientPart <= 100) {
      const insurancePart = 100 - clientPart;
      setNewPartner({ ...newPartner, Client_Part: clientPart, Insurance_Part: insurancePart });
      setErrors({ ...errors, Client_Part: null });
    } else {
      setErrors({ ...errors, Client_Part: "La part du client doit être comprise entre 0 et 100" });
    }
  };

  const handleInsurancePartChange = (e) => {
    const insurancePart = parseInt(e.target.value);
    if (!isNaN(insurancePart) && insurancePart >= 0 && insurancePart <= 100) {
      const clientPart = 100 - insurancePart;
      setNewPartner({ ...newPartner, Client_Part: clientPart, Insurance_Part: insurancePart });
      setErrors({ ...errors, Insurance_Part: null });
    } else {
      setErrors({ ...errors, Insurance_Part: "La part de l'assurance doit être comprise entre 0 et 100" });
    }
  };

  const handleAddPartner = async () => {
    if (validateForm()) {
      try {
        const response = await axios.post('api/partners', newPartner);
        setPartners([...partners, response.data]);
        setAddPartnerOpen(false);
        setNewPartner({
          Name: '',
          PhoneNumber: '',
          Consultation_Price: '',
          Client_Part: 0,
          Insurance_Part: 100,
          Description: '',
          imageUrl: '',
          tenantId: '',
        });
        setSnackbarMessage('Partenaire ajouté avec succès.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Erreur lors de l\'ajout du partenaire', error);
        setSnackbarMessage('Erreur lors de l\'ajout du partenaire.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarMessage('Veuillez remplir tous les champs obligatoires.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleEditPartner = (partner) => {
    setPartnerToEdit(partner);
    setNewPartner({ ...partner });
    setEditPartnerOpen(true);
  };

  const handleUpdatePartner = async () => {
    if (validateForm()) {
      try {
        const response = await axios.put(`api/partners/${partnerToEdit.id}`, newPartner);
        setPartners(partners.map((p) => (p.id === partnerToEdit.id ? { ...p, ...newPartner } : p)));
        setEditPartnerOpen(false);
        setSnackbarMessage('Partenaire mis à jour avec succès.');
        setSnackbarSeverity('success');
        setSnackbarOpen(true);
      } catch (error) {
        console.error('Erreur lors de la mise à jour du partenaire:', error.message);
        setSnackbarMessage('Erreur lors de la mise à jour du partenaire.');
        setSnackbarSeverity('error');
        setSnackbarOpen(true);
      }
    } else {
      setSnackbarMessage('Veuillez remplir tous les champs obligatoires.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };
  

  const handleDeletePartner = async (partnerId) => {
    try {
      await axios.delete(`api/partners/${partnerId}`);
      setPartners(partners.filter((p) => p.id !== partnerId));
      setSnackbarMessage('Partenaire supprimé avec succès.');
      setSnackbarSeverity('success');
      setSnackbarOpen(true);
    } catch (error) {
      console.error('Erreur lors de la suppression du partenaire', error);
      setSnackbarMessage('Erreur lors de la suppression du partenaire.');
      setSnackbarSeverity('error');
      setSnackbarOpen(true);
    }
  };

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const imageUrl = `/images/${file.name}`;
      setNewPartner({ ...newPartner, imageUrl });
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Partenaires
      </Typography>
      <Grid container spacing={3}>
        {partners.map((partner) => (
          <Grid item key={partner.id} xs={12} sm={6} md={4} lg={3}>
            <StyledCard>
              <CardMedia
                component="img"
                image={partner.imageUrl || 'https://via.placeholder.com/200'}
                title={partner.Name}
                sx={{ height: 200 }}
              />
              <CardContent>
                <Typography variant="h6">{partner.Name}</Typography>
                <Typography variant="body2">Téléphone: {partner.PhoneNumber}</Typography>
                <Typography variant="body2">Prix de la consultation: {partner.Consultation_Price}</Typography>
                <Typography variant="body2">Part Client: {partner.Client_Part}%</Typography>
                <Typography variant="body2">Part Assurance: {partner.Insurance_Part}%</Typography>
                <Typography variant="body2">Description: {partner.Description}</Typography>
                <IconButton size="small" onClick={() => handleEditPartner(partner)}>
                  <EditIcon fontSize="small" style={{ color: 'blue' }} />
                </IconButton>
                <IconButton size="small" onClick={() => handleDeletePartner(partner.id)}>
                  <DeleteIcon fontSize="small" style={{ color: 'blue' }} />
                </IconButton>
              </CardContent>
            </StyledCard>
          </Grid>
        ))}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <StyledCard onClick={() => setAddPartnerOpen(true)}>
            <CardContent style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              <AddCircleOutlineIcon style={{ fontSize: 50, color: 'blue' }} />
            </CardContent>
          </StyledCard>
        </Grid>
      </Grid>
      <Dialog open={addPartnerOpen} onClose={() => setAddPartnerOpen(false)}>
        <DialogTitle>Ajouter un nouveau partenaire</DialogTitle>
        <DialogContent>
          <TextField
            label="Nom"
            value={newPartner.Name}
            onChange={(e) => setNewPartner({ ...newPartner, Name: e.target.value })}
            fullWidth
            margin="normal"
            error={Boolean(errors.Name)}
            helperText={errors.Name}
          />
          <TextField
            label="Téléphone"
            value={newPartner.PhoneNumber}
            onChange={(e) => setNewPartner({ ...newPartner, PhoneNumber: e.target.value })}
            fullWidth
            margin="normal"
            error={Boolean(errors.PhoneNumber)}
            helperText={errors.PhoneNumber}
          />
          <TextField
            label="Prix de la consultation"
            value={newPartner.Consultation_Price}
            onChange={(e) => setNewPartner({ ...newPartner, Consultation_Price: e.target.value })}
            fullWidth
            margin="normal"
            error={Boolean(errors.Consultation_Price)}
            helperText={errors.Consultation_Price}
          />
          <TextField
            label="Part Client (%)"
            value={newPartner.Client_Part}
            onChange={handleClientPartChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.Client_Part)}
            helperText={errors.Client_Part}
          />
          <TextField
            label="Part Assurance (%)"
            value={newPartner.Insurance_Part}
            onChange={handleInsurancePartChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.Insurance_Part)}
            helperText={errors.Insurance_Part}
          />
          <TextField
            label="Description"
            value={newPartner.Description}
            onChange={(e) => setNewPartner({ ...newPartner, Description: e.target.value })}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="tenant-select-label">Mandataire</InputLabel>
            <Select
              labelId="tenant-select-label"
              value={newPartner.tenantId}
              onChange={(e) => setNewPartner({ ...newPartner, tenantId: e.target.value })}
              required
              error={Boolean(errors.tenantId)}
              helperText={errors.tenantId}
            >
              {tenants.map((tenant) => (
                <MenuItem key={tenant.id} value={tenant.id}>
                  {tenant.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Sélectionner une image
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
          {newPartner.imageUrl && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Image sélectionnée : {newPartner.imageUrl}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setAddPartnerOpen(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleAddPartner} color="primary">
            Ajouter
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={editPartnerOpen} onClose={() => setEditPartnerOpen(false)}>
        <DialogTitle>Modifier le partenaire</DialogTitle>
        <DialogContent>
          <TextField
            label="Nom"
            value={newPartner.Name}
            onChange={(e) => setNewPartner({ ...newPartner, Name: e.target.value })}
            fullWidth
            margin="normal"
            error={Boolean(errors.Name)}
            helperText={errors.Name}
          />
          <TextField
            label="Téléphone"
            value={newPartner.PhoneNumber}
            onChange={(e) => setNewPartner({ ...newPartner, PhoneNumber: e.target.value })}
            fullWidth
            margin="normal"
            error={Boolean(errors.PhoneNumber)}
            helperText={errors.PhoneNumber}
          />
          <TextField
            label="Prix de la consultation"
            value={newPartner.Consultation_Price}
            onChange={(e) => setNewPartner({ ...newPartner, Consultation_Price: e.target.value })}
            fullWidth
            margin="normal"
            error={Boolean(errors.Consultation_Price)}
            helperText={errors.Consultation_Price}
          />
          <TextField
            label="Part Client (%)"
            value={newPartner.Client_Part}
            onChange={handleClientPartChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.Client_Part)}
            helperText={errors.Client_Part}
          />
          <TextField
            label="Part Assurance (%)"
            value={newPartner.Insurance_Part}
            onChange={handleInsurancePartChange}
            fullWidth
            margin="normal"
            error={Boolean(errors.Insurance_Part)}
            helperText={errors.Insurance_Part}
          />
          <TextField
            label="Description"
            value={newPartner.Description}
            onChange={(e) => setNewPartner({ ...newPartner, Description: e.target.value })}
            fullWidth
            margin="normal"
            multiline
            rows={4}
          />
          <FormControl fullWidth margin="normal">
            <InputLabel id="tenant-select-label">Mandataire</InputLabel>
            <Select
              labelId="tenant-select-label"
              value={newPartner.tenantId}
              onChange={(e) => setNewPartner({ ...newPartner, tenantId: e.target.value })}
              required
              error={Boolean(errors.tenantId)}
              helperText={errors.tenantId}
            >
              {tenants.map((tenant) => (
                <MenuItem key={tenant.id} value={tenant.id}>
                  {tenant.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Button variant="contained" component="label" sx={{ mt: 2 }}>
            Sélectionner une image
            <input type="file" hidden onChange={handleImageChange} />
          </Button>
          {newPartner.imageUrl && (
            <Typography variant="body2" sx={{ mt: 2 }}>
              Image sélectionnée : {newPartner.imageUrl}
            </Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditPartnerOpen(false)} color="primary">
            Annuler
          </Button>
          <Button onClick={handleUpdatePartner} color="primary">
            Mettre à jour
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
      >
        <Alert onClose={() => setSnackbarOpen(false)} severity={snackbarSeverity}>
          {snackbarMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
}

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [tabValue, setTabValue] = useState(0);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="absolute" open={open}>
        <Toolbar sx={{ pr: '24px' }}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={toggleDrawer}
            sx={{
              marginRight: '36px',
              ...(open && { display: 'none' }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
            Assurance
          </Typography>
          <IconButton color="inherit">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon />
            </Badge>
          </IconButton>
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <Toolbar
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            px: [1],
          }}
        >
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Toolbar>
        <Divider />
        <List component="nav">
          {mainListItems}
          <Divider sx={{ my: 1 }} />
          {secondaryListItems}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <Toolbar />
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Partenaires" />
        </Tabs>
        {tabValue === 0 && (
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <PartnersConfig />
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
}
