import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import NotificationsIcon from '@mui/icons-material/Notifications';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Badge from '@mui/material/Badge';
import MenuIcon from '@mui/icons-material/Menu';
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import InfoIcon from '@mui/icons-material/Info';
import TextField from '@mui/material/TextField';
import { mainListItems, secondaryListItems } from './listItems';

const drawerWidth = 240;

const AppBarStyled = styled(AppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerStyled = styled(Drawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const theme = createTheme({
  palette: {
    primary: {
      main: '#34A836',
    },
    secondary: {
      main: '#e3f2fd',
    },
    background: {
      default: '#f4f6f8',
    },
    text: {
      primary: '#333',
      secondary: '#555',
    },
  },
  shape: {
    borderRadius: 8,
  },
});

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius,
  textAlign: 'center',
  marginBottom: theme.spacing(4),
  width: '100%',
  maxWidth: '800px',
  margin: 'auto',
}));

const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
  boxShadow: theme.shadows[4],
  borderRadius: theme.shape.borderRadius,
  marginTop: theme.spacing(4),
  maxWidth: '100%',
  margin: 'auto',
}));

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  '& th': {
    color: theme.palette.common.white,
    fontWeight: 'bold',
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  padding: theme.spacing(2),
  borderBottom: `1px solid ${theme.palette.divider}`,
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
}));

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [invoices, setInvoices] = useState([]);
  const [filteredInvoices, setFilteredInvoices] = useState([]);
  const [selectedInvoiceItems, setSelectedInvoiceItems] = useState([]);
  const [itemsDialogOpen, setItemsDialogOpen] = useState(false);
  const [totalPartClient, setTotalPartClient] = useState(0);
  const [totalFilteredAmount, setTotalFilteredAmount] = useState(0); // State für den Gesamtbetrag
  const [depotDialogOpen, setDepotDialogOpen] = useState(false);
  const [retraitDialogOpen, setRetraitDialogOpen] = useState(false);
  const [montant, setMontant] = useState('');
  const [description, setDescription] = useState('');
  const [dateRange, setDateRange] = useState({ startDate: '', endDate: '' });
  const [transactionType, setTransactionType] = useState('');

  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const response = await axios.get('api/finances');
        const fetchedInvoices = response.data;

        const totalPartClientSum = fetchedInvoices.reduce((total, invoice) => {
          const invoiceTotal = invoice.items.reduce((sum, item) => {
            if (invoice.Type === 'Depot') {
              return sum + item.amount;
            } else if (invoice.Type === 'Retrait') {
              return sum - item.amount;
            } else {
              return sum + (item.amount);
            }
          }, 0);

          return total + invoiceTotal;
        }, 0);

        setInvoices(fetchedInvoices);
        setTotalPartClient(totalPartClientSum);

        applyFilters(fetchedInvoices); // Apply filters to the newly fetched invoices
      } catch (error) {
        console.error('Error fetching invoices:', error);
      }
    };

    fetchInvoices(); // Initial fetch

  }, []);

  useEffect(() => {
    applyFilters(invoices); // Apply filters when dateRange or transactionType changes
  }, [dateRange, transactionType]);

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleInfoClick = (items) => {
    setSelectedInvoiceItems(items);
    setItemsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setItemsDialogOpen(false);
    setSelectedInvoiceItems([]);
  };

  const handleDepot = () => {
    setDepotDialogOpen(true);
  };

  const handleRetrait = () => {
    setRetraitDialogOpen(true);
  };

  const handleCloseDepotDialog = () => {
    setDepotDialogOpen(false);
    setMontant('');
    setDescription('');
  };

  const handleCloseRetraitDialog = () => {
    setRetraitDialogOpen(false);
    setMontant('');
    setDescription('');
  };

  const handleSubmitDepot = async () => {
    try {
      const items = [
        {
          description: description,
          amount: parseFloat(montant),
          part_client: 0,
          part_assurance: 0,
        },
      ];

      await axios.post('api/invoices', {
        patientId: 'Depot',
        visitId: 'Depot',
        tenantId: 'Depot',
        Type: 'Depot',
        items,
      });

      alert('Dépôt erfolgreich durchgeführt!');
      handleCloseDepotDialog();

      const response = await axios.get('api/finances');
      setInvoices(response.data);
      applyFilters(response.data); // Apply filters to the new data
    } catch (error) {
      console.error('Fehler beim Durchführen des Dépôt:', error);
      alert('Fehler beim Durchführen des Dépôt.');
    }
  };

  const handleSubmitRetrait = async () => {
    try {
      const items = [
        {
          description: description,
          amount: parseFloat(montant),
          part_client: 0,
          part_assurance: 0,
        },
      ];

      await axios.post('api/invoices', {
        patientId: 'Retrait',
        visitId: 'Retrait',
        tenantId: 'Retrait',
        Type: 'Retrait',
        items,
      });

      alert('Retrait erfolgreich durchgeführt!');
      handleCloseRetraitDialog();

      const response = await axios.get('api/finances');
      setInvoices(response.data);
      applyFilters(response.data); // Apply filters to the new data
    } catch (error) {
      console.error('Fehler beim Durchführen des Retrait:', error);
      alert('Fehler beim Durchführen des Retrait.');
    }
  };

  const applyFilters = (data) => {
    let filtered = data || invoices;

    if (dateRange.startDate && dateRange.endDate) {
      const start = new Date(dateRange.startDate);
      const end = new Date(dateRange.endDate);

      filtered = filtered.filter((invoice) => {
        const invoiceDate = new Date(invoice.created_at);
        return invoiceDate >= start && invoiceDate <= end;
      });
    }

    if (transactionType) {
      filtered = filtered.filter((invoice) => invoice.Type === transactionType);
    }

    setFilteredInvoices(filtered);

    // Gesamtbetrag der gefilterten Rechnungen berechnen
    const total = filtered.reduce((sum, invoice) => {
      const totalAmount = invoice.items.reduce((itemSum, item) => {
        if (invoice.Type === 'Depot') {
          return itemSum + item.amount;
        } else if (invoice.Type === 'Retrait') {
          return itemSum - item.amount;
        } else {
          return itemSum + item.amount;
        }
      }, 0);
      return sum + totalAmount;
    }, 0);

    setTotalFilteredAmount(total); // Gesamtbetrag im State speichern
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBarStyled position="absolute" open={open}>
          <Toolbar sx={{ pr: '24px' }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{ marginRight: '36px', ...(open && { display: 'none' }) }}
            >
              <MenuIcon />
            </IconButton>
            <Typography component="h1" variant="h6" color="inherit" noWrap sx={{ flexGrow: 1 }}>
              Gestion de la caisse principale
            </Typography>
            <IconButton color="inherit">
              <Badge badgeContent={4} color="secondary">
                <NotificationsIcon />
              </Badge>
            </IconButton>
          </Toolbar>
        </AppBarStyled>
        <DrawerStyled variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {mainListItems}
            <Divider sx={{ my: 1 }} />
            {secondaryListItems}
          </List>
        </DrawerStyled>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
            padding: 3,
            mt: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            gap: 4,
          }}
        >
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4, display: 'flex', flexDirection: 'column', alignItems: 'center', gap: 4 }}>
            <StyledPaper>
              <Typography variant="h6" gutterBottom sx={{ fontWeight: 'bold' }}>
                Caisse principale
              </Typography>
              <Typography variant="h4" sx={{ fontWeight: 'bold', color: theme.palette.primary.main }}>
                {totalPartClient.toLocaleString('de-DE', { style: 'currency', currency: 'MAD' })}
              </Typography>
              <Box sx={{ mt: 3, display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                <Button variant="contained" color="primary" onClick={handleDepot}>
                  Dépôt
                </Button>
                <Button variant="contained" color="secondary" onClick={handleRetrait}>
                  Retrait
                </Button>
              </Box>
            </StyledPaper>

            <Box sx={{ display: 'flex', gap: 2, mb: 4, alignItems: 'center' }}>
              <TextField
                label="Startdatum"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={dateRange.startDate}
                onChange={(e) => setDateRange({ ...dateRange, startDate: e.target.value })}
              />
              <TextField
                label="Enddatum"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={dateRange.endDate}
                onChange={(e) => setDateRange({ ...dateRange, endDate: e.target.value })}
              />
              <TextField
                select
                label="Transaktionstyp"
                value={transactionType}
                onChange={(e) => setTransactionType(e.target.value)}
                SelectProps={{ native: true }}
              >
                <option value="">Alle</option>
                <option value="Depot">Depot</option>
                <option value="Retrait">Retrait</option>
                <option value="Facture">Facture</option>
              </TextField>
              <Button variant="contained" onClick={() => applyFilters(invoices)}>
                Filtern
              </Button>

              {/* Bereich für Gesamtbetrag anzeigen, wenn Filter aktiv sind */}
              {(dateRange.startDate || dateRange.endDate || transactionType) && filteredInvoices.length > 0 && (
                <Box sx={{ ml: 4 }}>
                  <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                    Total: {totalFilteredAmount.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
                  </Typography>
                </Box>
              )}
            </Box>

            <StyledTableContainer component={Paper}>
              <Table>
                <StyledTableHead>
                  <TableRow>
                    <StyledTableCell>Numéro de facture</StyledTableCell>
                    <StyledTableCell>Date de facture</StyledTableCell>
                    <StyledTableCell>Montant</StyledTableCell>
                    <StyledTableCell>Type de Transaction</StyledTableCell>
                    <StyledTableCell>Info</StyledTableCell>
                  </TableRow>
                </StyledTableHead>
                <TableBody>
                  {filteredInvoices.map((invoice) => {
                    const totalAmount = invoice.items.reduce((sum, item) => {
                      if (invoice.Type === 'Depot') {
                        return sum + item.amount;
                      } else if (invoice.Type === 'Retrait') {
                        return sum - item.amount;
                      } else {
                        return sum + item.amount;
                      }
                    }, 0);

                    return (
                      <StyledTableRow key={invoice.id}>
                        <StyledTableCell>{invoice.id}</StyledTableCell>
                        <StyledTableCell>{new Date(invoice.created_at).toLocaleString('de-DE', { dateStyle: 'short', timeStyle: 'short' })}</StyledTableCell>
                        <StyledTableCell>{totalAmount.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</StyledTableCell>
                        <StyledTableCell>{invoice.Type}</StyledTableCell>
                        <StyledTableCell>
                          <IconButton onClick={() => handleInfoClick(invoice.items)}>
                            <InfoIcon />
                          </IconButton>
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </StyledTableContainer>

            <Dialog open={itemsDialogOpen} onClose={handleCloseDialog} maxWidth="sm" fullWidth>
              <DialogTitle>Information</DialogTitle>
              <DialogContent>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Description</TableCell>
                      <TableCell>Montant</TableCell>
                      <TableCell>Part Client</TableCell>
                      <TableCell>Part Assurance</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {selectedInvoiceItems.map((item) => (
                      <TableRow key={item.id}>
                        <TableCell>{item.description}</TableCell>
                        <TableCell>{item.amount.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</TableCell>
                        <TableCell>{(item.amount * item.part_client / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</TableCell>
                        <TableCell>{(item.amount * item.part_assurance / 100).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDialog} color="primary">Fermer</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={depotDialogOpen} onClose={handleCloseDepotDialog} maxWidth="sm" fullWidth>
              <DialogTitle>Dépôt</DialogTitle>
              <DialogContent>
                <TextField
                  label="Montant"
                  type="number"
                  fullWidth
                  value={montant}
                  onChange={(e) => setMontant(e.target.value)}
                  margin="normal"
                />
                <TextField
                  label="Description"
                  fullWidth
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  margin="normal"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseDepotDialog} color="secondary">Annuler</Button>
                <Button onClick={handleSubmitDepot} color="primary">OK</Button>
              </DialogActions>
            </Dialog>

            <Dialog open={retraitDialogOpen} onClose={handleCloseRetraitDialog} maxWidth="sm" fullWidth>
              <DialogTitle>Retrait</DialogTitle>
              <DialogContent>
                <TextField
                  label="Montant"
                  type="number"
                  fullWidth
                  value={montant}
                  onChange={(e) => setMontant(e.target.value)}
                  margin="normal"
                />
                <TextField
                  label="Description"
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  fullWidth
                  margin="normal"
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseRetraitDialog} color="secondary">Abbrechen</Button>
                <Button onClick={handleSubmitRetrait} color="primary">Confirmer</Button>
              </DialogActions>
            </Dialog>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}
